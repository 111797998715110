@import '../components/variable.css';

.admin-projects {
  position: relative;
  min-height: calc(100vh - 80px);
  height: auto;
  padding: 2rem;
  background: var(--background-color);
  color: var(--text-color);
  font-family: var(--terminal-font);
}

.terminal-window {
  max-width: 1000px;
  margin: 0 auto;
  background-color: var(--terminal-bg);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.terminal-header {
  background-color: var(--terminal-header);
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: var(--text-color);
  opacity: 0.5;
}

.terminal-title {
  color: var(--text-color);
  font-family: var(--terminal-font);
  margin-left: 1rem;
}

.terminal-content {
  padding: 1.5rem;
  font-family: var(--terminal-font);
}

.command-line {
  color: var(--text-color);
  margin-bottom: 1rem;
}

.prompt {
  color: var(--secondary-color);
  margin-right: 0.5rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.project-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
}

.tag {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.85rem;
}

.project-link {
  background-color: var(--secondary-color);
  color: var(--text-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--terminal-font);
  transition: background-color 0.3s ease;
}

.project-link:hover {
  background-color: var(--hover-color);
}

.project-link.delete {
  background-color: #dc3545;
  color: var(--text-color);
}

.project-link.delete:hover {
  background-color: #c82333;
}

.project-actions {
  display: flex;
  gap: 1rem;
  margin-top: 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.highlight {
  color: var(--secondary-color);
}

/* Styles pour le formulaire */
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input, textarea {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--secondary-color);
  color: var(--text-color);
  padding: 0.75rem;
  border-radius: 4px;
  font-family: var(--terminal-font);
}

input:focus, textarea:focus {
  outline: none;
  border-color: var(--hover-color);
}

.form-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.tech-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
}

.tech-button {
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  color: var(--text-color);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tech-button.active {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.image-upload {
  margin: 1rem 0;
}

.image-preview {
  margin-top: 15px;
  border: 1px solid var(--secondary-color);
  padding: 10px;
  border-radius: 4px;
}

.image-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.image-name {
  margin-top: 5px;
  color: var(--text-color);
  font-size: 0.9rem;
  text-align: center;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-project-btn {
  background-color: var(--secondary-color);
  color: var(--text-color);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.new-project-btn:hover {
  background-color: var(--hover-color);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

/* Ajout des styles responsive */
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr; /* Une seule colonne sur mobile */
    padding: 0 1rem;
  }

  .project-card {
    max-width: 100%;
    margin: 0 auto; /* Centre la carte */
    width: 100%;
  }

  .terminal-window {
    width: 95%;
    margin: 0 auto;
  }

  .admin-projects {
    padding: 1rem;
  }

  .dashboard-header {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
  }

  .project-actions {
    flex-direction: column;
    gap: 0.5rem;
  }

  .project-link {
    width: 100%;
    text-align: center;
  }

  .tech-buttons {
    justify-content: center;
  }
}

/* Ajustements pour les très petits écrans */
@media (max-width: 480px) {
  .project-card {
    padding: 1rem;
  }

  .project-tags {
    justify-content: center;
  }

  .modal-content {
    width: 95%;
    margin: 0 auto;
  }
}

.images-preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
}

.existing-images,
.new-images {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
  width: 100%;
}

.image-preview-item {
  position: relative;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  overflow: hidden;
  width: calc(50% - 0.5rem);
  aspect-ratio: 16/9;
}

.image-preview-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(220, 53, 69, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: all 0.3s ease;
}

.remove-image:hover {
  background: rgb(220, 53, 69);
  transform: scale(1.1);
}

.images-preview h4 {
  width: 100%;
  color: var(--text-color);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  opacity: 0.8;
}

/* Ajuster pour le mobile */
@media (max-width: 480px) {
  .image-preview-item {
    width: 100%;
  }
}

/* Styles pour le formulaire de connexion */
.admin-login {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-color);
  padding: 1rem;
}

.login-terminal {
  width: 100%;
  max-width: 500px;
  margin: 0;
}

.login-form {
  margin-top: 2rem;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-group label {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: var(--terminal-font);
}

.input-group input {
  flex: 1;
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--secondary-color);
  color: var(--text-color);
  padding: 0.5rem;
  font-family: var(--terminal-font);
  font-size: 1rem;
}

.input-group input:focus {
  outline: none;
  border-bottom-color: var(--hover-color);
}

.login-button {
  width: 100%;
  background: transparent;
  border: 1px solid var(--secondary-color);
  color: var(--text-color);
  padding: 0.75rem;
  margin-top: 2rem;
  font-family: var(--terminal-font);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
}

.login-button:hover {
  background: var(--secondary-color);
  transform: translateY(-2px);
}

.login-button .prompt {
  color: var(--secondary-color);
}

.login-button:hover .prompt {
  color: var(--text-color);
}

/* Animation de clignotement pour le curseur */
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.input-group input::after {
  content: '|';
  margin-left: 2px;
  animation: blink 1s infinite;
} 