.page-transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 0, 43, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in;
}

.terminal-popup {
  background: var(--terminal-bg);
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(157, 78, 221, 0.3);
}

.terminal-content {
  padding: 1.5rem;
  font-family: var(--terminal-font);
  color: var(--primary-color);
  font-size: 1.1rem;
  background: var(--background-color);
  min-height: 100px;
  line-height: 1.5;
}

.prompt {
  color: var(--secondary-color);
  margin-right: 8px;
}

.cursor {
  display: inline-block;
  width: 8px;
  height: 1.2rem;
  background-color: var(--secondary-color);
  margin-left: 4px;
  animation: blink 1s infinite;
  vertical-align: middle;
}

.command-end {
  display: inline-block;
  color: var(--secondary-color);
  margin-left: 4px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.terminal-header {
  background: var(--terminal-header);
  padding: 0.8rem;
  display: flex;
  align-items: center;
}

.terminal-buttons {
  display: flex;
  gap: 0.5rem;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.terminal-button:nth-child(1) {
  background: #ff5f56;
}

.terminal-button:nth-child(2) {
  background: #ffbd2e;
}

.terminal-button:nth-child(3) {
  background: #27c93f;
}

.terminal-title {
  color: var(--primary-color);
  margin-left: 1rem;
  font-size: 0.9rem;
  opacity: 0.8;
} 