@import '../components/variable.css';

.contact {
  min-height: 100vh;
  background-color: var(--background-color);
  padding: 2rem;
  font-family: var(--terminal-font);
}

.contact-container {
  max-width: 1000px;
  margin: 0 auto;
}

.contact-content {
  color: var(--text-color);
  padding: 2rem;
}

.contact h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contact h1::before {
    content: "$ ";
    color: var(--secondary-color);
  }

.contact h2 {
  font-size: 1.8rem;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
}

.contact h2::before {
  content: "> ";
}

.highlight {
  color: var(--secondary-color);
}

.terminal-window {
  background-color: var(--terminal-bg);
  border-radius: 8px;
  margin-top: 2rem;
  overflow: hidden;
  border: 1px solid var(--secondary-color);
  box-shadow: 0 0 20px rgba(157, 78, 221, 0.2);
}

.terminal-header {
  background-color: var(--terminal-header);
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  opacity: 0.7;
}

.terminal-content {
  padding: 1.5rem;
}

.command-line {
  margin: 1rem 0;
  color: var(--text-color);
}

.prompt {
  color: var(--secondary-color);
  margin-right: 0.5rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  background-color: var(--background-color);
  border: 1px solid var(--secondary-color);
  color: var(--text-color);
  margin-top: 0.5rem;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--hover-color);
  box-shadow: 0 0 5px var(--secondary-color);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(224, 224, 224, 0.5);
}

.submit-btn {
  background-color: transparent;
  color: var(--secondary-color);
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  color: var(--hover-color);
  transform: translateX(10px);
}

.response {
  color: var(--text-color);
  margin-left: 1.5rem;
  line-height: 1.6;
}

.submit-status {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  font-family: var(--terminal-font);
}

.submit-status.success {
  color: #4caf50;
  background-color: rgba(76, 175, 80, 0.1);
  border: 1px solid #4caf50;
}

.submit-status.error {
  color: #f44336;
  background-color: rgba(244, 67, 54, 0.1);
  border: 1px solid #f44336;
}

.submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
} 