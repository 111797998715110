@import '../components/variable.css';

.project-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.project-popup {
  background-color: var(--terminal-header);
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  border-radius: 6px;
  overflow: hidden;
  animation: fadeIn 0.3s ease-out;
  border: 1px solid var(--secondary-color);
}

/* Terminal Header */
.popup-header {
  background: var(--terminal-header);
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--secondary-color);
}

.terminal-buttons {
  display: flex;
  gap: 8px;
  position: absolute;
  left: 10px;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--secondary-color);
  opacity: 0.7;
}

.popup-header h2 {
  margin: 0;
  width: 100%;
  text-align: center;
  color: var(--text-color);
  font-size: 1rem;
  font-family: var(--terminal-font);
}

.close-button {
  position: absolute;
  right: 15px;
  background: none;
  border: none;
  color: var(--secondary-color);
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.close-button:hover {
  opacity: 1;
}

/* Terminal Content */
.popup-content {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(90vh - 40px);
  background: var(--background-color);
  font-family: var(--terminal-font);
}

/* Command Line Style */
.command-line {
  margin: 15px 0;
  color: var(--text-color);
  font-family: var(--terminal-font);
}

.prompt {
  color: var(--secondary-color);
  margin-right: 5px;
}

/* Project Image */
.project-image {
  margin: 15px 0;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: auto;
  display: block;
}

/* Technologies Tags */
.technologies {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  padding: 0.5rem 0.8rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-family: var(--terminal-font);
  border: none;
  cursor: default;
  transition: all 0.3s ease;
  text-decoration: none;
}

.tag:hover {
  opacity: 0.8;
  transform: translateY(-1px);
}

/* Description and Features */
.description, .features {
  margin: 20px 0;
  line-height: 1.6;
  color: var(--text-color);
}

.description p {
  color: var(--text-color);
  font-family: var(--terminal-font);
  font-size: 0.9rem;
}

.features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.features li {
  color: var(--text-color);
  font-family: var(--terminal-font);
  font-size: 0.9rem;
  padding-left: 20px;
  position: relative;
  margin-bottom: 8px;
}

.features li::before {
  position: absolute;
  left: 0;
  color: var(--secondary-color);
}

/* Project Link */
.project-link-container {
  margin-top: 20px;
  text-align: right;
}

.project-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: transparent;
  color: var(--secondary-color);
  text-decoration: none;
  border-radius: 4px;
  font-family: var(--terminal-font);
  font-size: 0.9rem;
  border: 1px solid var(--secondary-color);
  transition: all 0.3s;
}

.project-link:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

/* Scrollbar */
.popup-content::-webkit-scrollbar {
  width: 8px;
}

.popup-content::-webkit-scrollbar-track {
  background: var(--primary-color);
}

.popup-content::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 4px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.carousel {
  position: relative;
  margin: 15px 0;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.carousel-button:hover {
  background: var(--secondary-color);
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--text-color);
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  opacity: 1;
  background: var(--secondary-color);
}

.project-image {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.project-image img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

