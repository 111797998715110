@import '../components/variable.css';

.install-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
}

.start-button {
    padding: 0.8rem 1.5rem;
    font-family: var(--terminal-font);
    font-size: 2rem;
    background-color: transparent;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
  }
  
  .start-button:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    box-shadow: 0 0 15px rgba(157, 78, 221, 0.4);
  }

.terminal {
  width: 60%;
  background-color: var(--terminal-bg);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);
}

.terminal-header {
  background-color: var(--terminal-header);
  padding: 10px;
  display: flex;
  gap: 8px;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.red { background-color: #ff5f56; }
.yellow { background-color: #ffbd2e; }
.green { background-color: #27c93f; }

.terminal-content {
  padding: 20px;
  font-family: var(--terminal-font);
  color: var(--text-color);
}

.command-line {
  margin: 10px 0;
}

.prompt {
  color: var(--secondary-color);
}

.cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.language-select {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.language-select button {
  background-color: transparent;
  color: var(--text-color);
  border: none;
  font-family: var(--terminal-font);
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-select button:before {
  content: '> ';
  color: var(--secondary-color);
} 