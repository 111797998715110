@import '../components/variable.css';

.about {
  position: relative;
  min-height: calc(100vh - 80px);
  height: auto;
  padding: 2rem;
  background: var(--background-color);
  color: var(--text-color);
  font-family: var(--terminal-font);
}

.about-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.about h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.about h1::before {
  content: "$ ";
  color: var(--secondary-color);
}

.about h2 {
  font-size: 1.8rem;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
}

.about h2::before {
  content: "> ";
}

.about p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.8;
  line-height: 1.6;
}

.terminal-window {
  background: var(--terminal-bg);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(157, 78, 221, 0.2);
  margin-top: 2rem;
}

.terminal-header {
  background: var(--header-terminal);
  padding: 10px;
  display: flex;
  gap: 8px;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--secondary-color);
  opacity: 0.7;
}

.terminal-content {
  padding: 20px;
  font-family: var(--terminal-font);
}

.command-line {
  margin: 15px 0;
  color: var(--text-color);
}

.prompt {
  color: var(--secondary-color);
  margin-right: 10px;
}

.response {
  color: var(--text-color);
  margin: 10px 0 20px 20px;
  line-height: 1.6;
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0 20px 20px;
}

.skill {
  background-color: transparent;
  color: var(--secondary-color);
  padding: 5px 12px;
  font-size: 0.9rem;
  border: 1px solid var(--terminal-bg);
  transition: all 0.3s ease;
}

.skill:hover {
  background: var(--secondary-color);
  color: var(--primary-color);
  transform: translateY(-2px);
}

.highlight {
  color: var(--secondary-color);
}

@media (max-width: 855px) {
  .about-container {
    padding: 1rem;
  }

  .about h1 {
    font-size: 2rem;
  }

  .about h2 {
    font-size: 1.5rem;
  }

  .skills-grid {
    margin: 10px 0;
  }
} 