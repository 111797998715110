@import '../components/variable.css';

.projects {
  position: relative;
  min-height: calc(100vh - 80px);
  height: auto;
  padding: 2rem;
  background: var(--background-color);
  color: var(--text-color);
  font-family: var(--terminal-font);
}

.projects-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
}

.projects-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.projects h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.projects h1::before {
  content: "$ ";
  color: var(--secondary-color);
}

.projects h2 {
  font-size: 1.8rem;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
}

.projects h2::before {
  content: "> ";
}

.terminal-window {
  width: 1000px;
  background: var(--terminal-bg);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(157, 78, 221, 0.2);
  margin: 2rem auto;
}

.terminal-header {
  background: var(--terminal-header);
  padding: 10px;
  display: flex;
  gap: 8px;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--secondary-color);
  opacity: 0.7;
}

.terminal-content {
  padding: 20px;
  font-family: var(--terminal-font);
}

.command-line {
  margin: 15px 0;
  color: var(--text-color);
}

.prompt {
  color: var(--secondary-color);
  margin-right: 10px;
}

.response {
  color: var(--text-color);
  margin: 10px 0 20px 20px;
  line-height: 1.6;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 2rem 0;
  max-width: 100%;
  padding: 0 1rem;
}

.project-card {
  width: 100%;
  max-width: 450px;
  background: var(--background-color);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-card h3 {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 10px 0;
}

.project-card .tag, .project-tags span {
    padding: 0.5rem .8rem;
    font-family: var(--terminal-font);
    font-size: .8rem;
    background-color: transparent;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    cursor: default;
    transition: all 0.3s ease;
    text-decoration: none;
  }
  
  .project-card .tag:hover, .project-tags span:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    box-shadow: 0 0 15px rgba(157, 78, 221, 0.4);
  }

  .project-links {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  
.project-card .project-link {
    padding: 0.5rem .8rem;
    font-family: var(--terminal-font);
    font-size: .8rem;
    background-color: transparent;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    gap: 10px;
  }
  
  .project-card .project-link:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    box-shadow: 0 0 15px rgba(157, 78, 221, 0.4);
  }

.highlight {
  color: var(--secondary-color);
}

@media (max-width: 855px) {
  .terminal-window {
    width: 95%;
    margin: 1rem auto;
  }

  .projects-grid {
    grid-template-columns: 1fr;
    max-width: 600px;
    margin: 2rem auto;
  }

  .project-card {
    max-width: 100%;
    margin: 0 auto;
  }

  .project-tags {
    justify-content: center;
  }

  .project-links {
    flex-direction: column;
    gap: 10px;
  }

  .project-link {
    width: 100%;
    text-align: center;
  }

  .projects-container {
    padding: 1rem;
  }

  .projects h1 {
    font-size: 2rem;
  }

  .projects h2 {
    font-size: 1.5rem;
  }
} 