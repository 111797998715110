.code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
  
  .code-animation {
    background: var(--terminal-bg);
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(157, 78, 221, 0.2);
  }
  
  .terminal-header {
    background: var(--terminal-header);
    padding: 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .terminal-buttons {
    display: flex;
    gap: 0.5rem;
  }
  
  .terminal-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ff5f56;
  }
  
  .terminal-button:nth-child(2) {
    background: #ffbd2e;
  }
  
  .terminal-button:nth-child(3) {
    background: #27c93f;
  }
  
  .terminal-title {
    color: #fff;
    margin-left: 1rem;
    font-size: 0.9rem;
    font-family: var(--terminal-font);
  }
  
  .code-animation pre {
    margin: 0;
    padding: 1.5rem;
    white-space: pre-wrap;
    font-family: var(--terminal-font);
    color: var(--primary-color);
    font-size: 0.9rem;
    line-height: 1.5;
    background: var(--background-color);
    height: 600px;
    overflow-y: auto;
    
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  
  /* Masquer la scrollbar pour Chrome, Safari et Opera */
  .code-animation pre::-webkit-scrollbar {
    display: none;
  }
  
  /* Pour assurer que le contenu défile automatiquement */
  .code-animation pre code {
    display: block;
  }
  
  code {
    color: var(--secondary-color);
  }
  
  @media (max-width: 855px) {
    .code-container {
      padding: 1rem;
    }
  }
  
  .fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
  
  .fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
