@import '../components/variable.css';

.footer {
  background-color: var(--terminal-bg);
  padding: 1.5rem;
  border-top: 1px solid var(--secondary-color);
  margin-top: auto;
  text-align: center;
}

.footer-content {
  font-family: var(--terminal-font);
  color: var(--text-color);
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.footer-content p {
  opacity: 0.8;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  color: var(--text-color);
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: var(--secondary-color);
  transform: translateY(-2px);
}

