.navbar {
  background: var(--terminal-bg);
  height: var(--navbar-height);
  position: sticky;
  top: 0;
  z-index: 999;
  font-family: var(--terminal-font);
  border-bottom: 1px solid var(--secondary-color);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
}

.navbar-logo {
  color: var(--secondary-color);
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
}

.logo-text::before {
  content: "~/";
  color: var(--primary-color);
  margin-right: 5px;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 1.5rem;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.nav-link::before {
  content: "./";
  color: var(--secondary-color);
  margin-right: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.nav-link:hover {
  color: var(--secondary-color);
}

.nav-link:hover::before {
  opacity: 1;
}

.blog-link {
  background-color: var(--secondary-color);
  color: var(--primary-color) !important;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.blog-link:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color) !important;
  transform: translateY(-2px);
}

.blog-link::before {
  display: none;
}

.active-link {
  color: var(--secondary-color);
}

.active-link::before {
  opacity: 1;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: var(--secondary-color);
  margin: 5px 0;
  transition: 0.4s;
}

.nav-logo-img {
  height: 50px;
  width: auto;
  margin-right: 10px;
}

.logo-text {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 855px) {
  .navbar-container {
    padding: 0 10px;
  }

  .menu-icon {
    display: block;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: -100%;
    width: 100%;
    background: var(--terminal-bg);
    padding: 20px 0;
    transition: 0.3s ease-in-out;
    border-bottom: 1px solid var(--secondary-color);
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 16px 0;
  }

  .nav-logo-img {
    height: 30px;
  }

  .logo-text {
    font-size: 1rem;
  }
} 