:root {
  --primary-color: #e0e0e0;     /* Texte principal en blanc cassé */
  --secondary-color: #9d4edd;   /* Violet vif pour les accents */
  --hover-color: #7b2cbf;      /* Violet plus foncé pour le hover */
  --background-color: #10002b;  /* Fond très sombre avec une teinte violette */
  --text-color: #e0e0e0;       /* Texte en blanc cassé */
  --terminal-font: 'Fira Code', 'Courier New', monospace;
  --terminal-bg: #240046;      /* Fond du terminal en violet foncé */
  --terminal-header: #3c096c;  /* En-tête du terminal en violet */
  --navbar-height: 80px;
}