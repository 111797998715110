@import './variable.css';

.hero {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  min-height: calc(100vh - 80px);
  height: auto;
  padding: 2rem;
  background: var(--background-color);
  color: var(--text-color);
  font-family: var(--terminal-font);
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.buttons-container {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.hero h1::before {
  content: "$ ";
  color: var(--secondary-color);
}

.hero h2 {
  font-size: 1.8rem;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
}

.hero h2::before {
  content: "> ";
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.8;
}

.cta-button {
  padding: 0.8rem 1.5rem;
  font-family: var(--terminal-font);
  font-size: 1rem;
  background-color: transparent;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.cta-button:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  box-shadow: 0 0 15px rgba(157, 78, 221, 0.4);
}

@media (max-width: 855px) {
  .hero {
    grid-template-columns: 1fr;
    height: auto;
  }
} 

.language-selector {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
}

.language-selector button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-selector button:hover {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Ajuster le style du composant de traduction dans le contexte du Hero */
.hero .translate-container {
  border: none;
  padding: 0;
  margin: 0;
}

.hero .translated-text {
  font-size: inherit;
  background: none;
  padding: 0;
}

.hero .original-text,
.hero button[disabled] {
  display: none;
}