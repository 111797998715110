.App {
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Assurez-vous que le contenu principal prend tout l'espace disponible */
main {
  flex: 1;
}
